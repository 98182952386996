import(/* webpackMode: "eager" */ "/var/www/hentaimanga.online/build/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/var/www/hentaimanga.online/build/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/var/www/hentaimanga.online/build/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/var/www/hentaimanga.online/build/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Exo_2\",\"arguments\":[{\"variable\":\"--font-inter\",\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"exo2\"}");
;
import(/* webpackMode: "eager" */ "/var/www/hentaimanga.online/build/node_modules/react-horizontal-scrolling-menu/dist/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["SkeletonTheme"] */ "/var/www/hentaimanga.online/build/node_modules/react-loading-skeleton/dist/index.js");
;
import(/* webpackMode: "eager" */ "/var/www/hentaimanga.online/build/node_modules/react-loading-skeleton/dist/skeleton.css");
;
import(/* webpackMode: "eager" */ "/var/www/hentaimanga.online/build/node_modules/react-notifications-component/dist/theme.css");
;
import(/* webpackMode: "eager" */ "/var/www/hentaimanga.online/build/node_modules/react-tooltip/dist/react-tooltip.min.css");
;
import(/* webpackMode: "eager", webpackExports: ["addToast","handleExceptionRequest"] */ "/var/www/hentaimanga.online/build/src/app/lib/utils/toast.util.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/hentaimanga.online/build/src/app/providers/QueryProvider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/hentaimanga.online/build/src/app/providers/UserSettingsProvider/index.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/hentaimanga.online/build/src/app/ui/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/hentaimanga.online/build/src/app/ui/layout/FooterWrapper/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/hentaimanga.online/build/src/app/ui/layout/ReactNotificationWrapper/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/hentaimanga.online/build/src/app/ui/layout/RouteEvent/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/hentaimanga.online/build/src/components/layout/Header/HeaderWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/hentaimanga.online/build/src/components/layout/Header/NavContainer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/hentaimanga.online/build/src/components/layout/Header/UserContainer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/hentaimanga.online/build/src/components/providers/SessionProviderWrapper/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/hentaimanga.online/build/src/components/providers/TooltipProviderWrapper/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/hentaimanga.online/build/src/providers/HeaderProvider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/hentaimanga.online/build/src/providers/ModalProvider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/hentaimanga.online/build/src/providers/ThemeProvider/index.tsx");
